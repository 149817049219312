import { UserStorage } from 'account/enums/user.enum';
import { LoginData } from 'account/interfaces/login-data.interface';
import { HttpClient } from 'commons/ts/http.client';
import { RestEndPoint } from 'commons/ts/utils/rest.endpoints';
import { Base64 } from 'js-base64';
import { AccountService } from './account.service';
import { UserInfo } from 'account/interfaces/user-info.interface';

class Service {
    async authenticateGV(email: string, password: string, provider: string, captchaToken: string): Promise<void> {
        try {

            if (password.length > 64) {
                throw 'Email e/ou senha inválidos';
            }

            const headers: any = {
                Authorization: `Basic ${Base64.encode(`${email}:${password}`)}`,
                'X-Provider': provider,
                'X-Captcha' : captchaToken
            };

            const loginData: LoginData = await HttpClient.get({ url: RestEndPoint.AUTHENTICATION, headers });

            if (!loginData.token) {
                throw 'Email e/ou senha inválidos';
            }

            await this.authenticate(email);
        } catch (error) {
            if (!!error.message && !!error.message.message && error.message.message.toLowerCase() === 'network error') {
                throw 'Verifique sua conexão com a internet';
            }
            throw error;
        }
    }

    async authenticateSocial(provider: string, token: string, picture?: string): Promise<void> {
        try {
            const headers: any = {
                Authorization: token,
                'X-Provider': provider,
            };

            const loginData: LoginData = await HttpClient.get({ url: RestEndPoint.AUTHENTICATION, headers });

            if (!loginData.token) {
                throw 'Não foi possível autenticar';
            }

            await this.authenticate();

            if (!!picture)
                localStorage.setItem(UserStorage.PICTURE, picture);

        } catch (error) {
            if (!!error.message && !!error.message.message && error.message.message.toLowerCase() === 'network error') {
                throw 'Verifique sua conexão com a internet';
            }
            throw error;
        }
    }

    async authenticate(email: string = ''): Promise<void> {
        try {
            const user: UserInfo = await AccountService.getUser();

            if (!user) {
                throw '';
            }

            localStorage.setItem(UserStorage.EMAIL, user.email.toLowerCase().trim());
        } catch (error) {
            await AuthenticationService.unauthenticate();
            throw error;
        }
    }

    async unauthenticate(): Promise<void> {
        await AccountService.logout();
        localStorage.removeItem(UserStorage.EMAIL);
        localStorage.removeItem(UserStorage.PICTURE);
    }

    async isAuthenticated(): Promise<boolean> {
        const user: UserInfo = await AccountService.getUser();
        return !!user;
    }
}

export const AuthenticationService: Service = new Service();
export default AuthenticationService;
