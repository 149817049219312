import * as UserSession from '../../../../../../src/_v2/application/components/user/user-session';
import LoginHeaderComponents from './interfaces/components.interface';
import LoginHeaderSelectors from './interfaces/selectors.interface';
import { GVLogin } from '../gv-login/gv-login';
import { AuthenticationService } from 'account/services/authentication.service';

import { LoadingComponent } from 'commons/ts/loading.component';
import { GoogleOAuthProvider } from 'google-oauth-gsi';
import { Provider } from 'account/enums/provider.enum';

/****
 * Este componente é carregado em todas as páginas, sua referência foi inseriada no arquivo:
 * view/pages/common-bundle/commons.ts;
 *
 * Seu CSS foi inserido como referência no CSS do arquivo gv-header.scss;
 * O script para login com Facebook ou Google está na master.hbs (carregado com LazyLoad);
 * O HTML dele deve ser inserido nos arquivos header.hbs;
 */
export class GVLoginHeader {

    private isLogged: boolean = false;
    private loggedUser: UserSession.User;
    private gvLoginComponent: GVLogin;
    private googleProvider: GoogleOAuthProvider;

    private selectors: LoginHeaderSelectors = {
        button: '.gv-login-header',
        buttonOutside: '.gv-login-button-outside',
        label: '.gv-login-header .lht-login',
        panel: '.gv-login-header .lh-panel',
        photo: '.gv-login-header .lh-photo',
        buttonOrders: '.gv-login-header .lh-panel .lhp-btn-orders',
        buttonCreditCards: '.gv-login-header .lh-panel .lhp-btn-creditcards',
        buttonProfile: '.gv-login-header .lh-panel .lhp-btn-profile',
        buttonWallet: '.gv-login-header .lh-panel .lhp-btn-wallet',
        buttonLogout: '.gv-login-header .lh-panel .lhp-btn-logout',

        buttonLogoutSideBar: '.shell-sidebar .shell-sidebar-navigation .list_user_exit .lhp-btn-logout',
        buttonLoginSideBar: '.shell-sidebar-login .link-login',
        btnCreditCardsSidebar: '.shell-sidebar-navigation .list_user .link_card',
        btnProfileSidebar: '.shell-sidebar-navigation .list_user .link_profile',
        btnWalletSidebar: '.shell-sidebar-navigation .list_user .link_wallet',

        walletMenuItem: 'li.js-wallet-menu-item',
        walletMenuItemSidebar: 'li.js-wallet-menu-item-sidebar',
        myAccountNewTag: 'li.js-my-account-menu-item p',
        myAccountNewTagSidebar: 'li.js-my-account-menu-item-sidebar p',

        listItemSidebarLogin: '.js-account-amplitude',
    };

    private components: LoginHeaderComponents = {
        button: null,
        buttonOutside: null,
        label: null,
        panel: null,
        photo: null,
        buttonOrders: null,
        buttonCreditCards: null,
        buttonProfile: null,
        buttonWallet: null,
        buttonLogout: null,

        buttonLogoutSideBar: null,
        buttonLoginSideBar: null,
        btnCreditCardsSidebar: null,
        btnProfileSidebar: null,
        btnWalletSidebar: null,

        walletMenuItem: null,
        walletMenuItemSidebar: null,
        myAccountNewTag: null,
        myAccountNewTagSidebar: null,

        listItemSidebarLogin: null,
        loading: new LoadingComponent('load', 'Carregando')
    };

    constructor() {
        try {
            this.cacheDom();
            this.validateLogged();
            this.gvLoginComponent = new GVLogin();
        }
        catch (error) {
            if (process.env.NODE_ENV !== 'production') {
                console.error(error);
            }
        }
    }

    initializeGoogle() {
        this.googleProvider = new GoogleOAuthProvider({
            clientId: window["googleKey"],
            onScriptLoadSuccess: () => {
                this.oneTap()
            },
        });
    }

    oneTap() {
        if(this.isLogged) {
            return;
        }
        const tap = this.googleProvider.useGoogleOneTapLogin({
            cancel_on_tap_outside: true,
            onSuccess: async (res) => {
                await AuthenticationService.authenticateSocial(Provider.GOOGLE, res.credential);
                location.reload();
            },
            onError() {
                console.error('fail to authenticate');
            },
            use_fedcm_for_prompt: true
        })
        tap();
    }

    /**
     * Handles para verificar se o usuário está logado
     */

    validateLogged(): void {
        UserSession.current((user: UserSession.User) => {
            this.isLogged = !!user;
            this.loggedUser = user;
            if (this.isLogged) {
                this.authenticateUser();
                return;
            }
            this.initializeGoogle();
        });
    }

    authenticateUser(): void {
        const userPicture = localStorage.getItem('user-picture');
        if (this.components.photo) {
            this.components.photo.innerHTML = '';

            if (!!userPicture) {
                const img = document.createElement('img');
                img.classList.add('lhp-img');
                img.src = userPicture;

                this.components.photo.appendChild(img);
            } else {
                const i = document.createElement('i');
                i.classList.add('material-icons');
                i.classList.add('lhp-icon');
                i.textContent = 'person';

                this.components.photo.appendChild(i);
            }
        }
        if (this.components.label) {
            this.components.label.textContent = `Olá, ${this.loggedUser.firstName}`;
        }
        if (this.components.button) {
            this.components.button.classList.add('lh-logged');
            this.components.button.addEventListener('click', () => this.toggle());
        }

        if (Array.isArray(this.loggedUser.features) && this.loggedUser.features.includes("WALLET")) {
            if (this.components.walletMenuItem) {
                this.components.walletMenuItem.style.display = "list-item";
                if (this.components.walletMenuItemSidebar) {
                    this.components.walletMenuItemSidebar.style.display = "list-item";
                }
            }
        }

        this.attachLoggedEvents();
    }

    /**
     * Métodos de UI
     */

    /**
     * Inicializa os objetos do DOM.
     * (deve possuir o mesmo nome na variavel 'selectors' e na variavel 'components').
     */
    private cacheDom(): void {
        for (const key in this.selectors) {
            if (!this.components.hasOwnProperty(key)) continue;

            if (typeof this.selectors[key] === 'object') {
                for (const subKey in this.selectors[key]) {
                    if (this.components[key].hasOwnProperty(subKey))
                        this.components[key][subKey] = document.querySelector(this.selectors[key][subKey]);
                }
                continue;
            }

            this.components[key] = document.querySelector(this.selectors[key]);
        }
        this.components.buttonOutside = Array.from(document.querySelectorAll(this.selectors.buttonOutside));
    }

    private attachLoggedEvents(): void {
        // Last Orders Menu Click
        if (this.components.buttonOrders) {
            this.components.buttonOrders.addEventListener('click', () => location.href = `${location.origin}/minhas-viagens`);
        }

        // Credit Cards Menu Click
        if (this.components.buttonCreditCards) {
            this.components.buttonCreditCards.addEventListener('click', () => location.href = `${location.origin}/meus-cartoes`);
        }
        // Credit Cards Menu Click Sidebar
        if (this.components.btnCreditCardsSidebar) {
            this.components.btnCreditCardsSidebar.addEventListener('click', () => location.href = `${location.origin}/meus-cartoes`);
        }

        // Profile Menu Click
        if (this.components.buttonProfile) {
            this.components.buttonProfile.addEventListener('click', () => location.href = `${location.origin}/perfil`);
        }
        // Profile Menu Click Sidebar
        if (this.components.btnProfileSidebar) {
            this.components.btnProfileSidebar.addEventListener('click', () => location.href = `${location.origin}/perfil`);
        }

        // Wallet Menu Click
        if (this.components.buttonWallet) {
            this.components.buttonWallet.addEventListener('click', () => location.href = `${location.origin}/carteira`);
        }
        // Wallet Menu Click Sidebar
        if (this.components.btnWalletSidebar) {
            this.components.btnWalletSidebar.addEventListener('click', () => location.href = `${location.origin}/carteira`);
        }

        // Logout Menu Click
        if (this.components.buttonLogout) {
            this.components.buttonLogout.addEventListener('click', this.logout);
        }
        //logout Sidebar Click
        if (this.components.buttonLogoutSideBar) {
            this.components.buttonLogoutSideBar.addEventListener('click', this.logout);
        }
    }

    logout = async (): Promise<void> => {
        try {
            this.components.loading.appear(document.body);
            await AuthenticationService.unauthenticate();
        }
        catch (e) { }
        finally {
            location.href = `${location.origin}`;
        }
    }

    private toggle(): void {
        this.components.panel.classList.toggle('show');
    }
}

window.addEventListener('load', () => new GVLoginHeader());
